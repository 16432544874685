import React, { useEffect, useRef, useState } from "react";
import "./UserDetails.css";
import closeModal from "assets/Icons/MyHackathons/closeModal.svg";
import GithubIcon from "assets/Icons/MyHackathons/newGithub.svg";
import AddAssessment from "assets/Icons/MyHackathons/AddAssessment.svg";
import TwitterIcon from "assets/Icons/MyHackathons/newTwitter.svg";
import submitArrow from "assets/Icons/MyHackathons/submitArrow.svg";
import MyAssessment from "assets/Icons/MyHackathons/MyAssessment.svg";
import { Modal } from "react-bootstrap";
import contactProfile from "assets/Icons/MyHackathons/contactProfile.svg";
import contactEmail from "assets/Icons/MyHackathons/contactEmail.svg";
import Library from "assets/Icons/MyHackathons/Library.svg";
import contactPhone from "assets/Icons/MyHackathons/contactPhone.svg";
import profileEmailCopy from "assets/Icons/MyHackathons/profileEmailCopy.svg";
import startArrow from "assets/Icons/MyHackathons/startArrow.svg";
import greenCheck from "assets/Icons/Jobs/greencheck.svg";
import greyCheck from "assets/Icons/Jobs/greycheck.svg";
import machinehackBadgeRemove from "assets/Icons/MyHackathons/machinehackBadgeRemove.svg";
import earnedBadgesRemove from "assets/Icons/MyHackathons/earnedBadgesRemove.svg";
import dropdownArrow from "assets/Icons/MyHackathons/dropdownArrow.svg";
import LinkedinIcon from "assets/Icons/MyHackathons/newLinkedin.svg";
import addNote from "assets/Images/MyHackathons/addNote.svg"
import Note from "assets/Images/MyHackathons/note.svg"
// import "react-calendar-heatmap/dist/styles.css";
import UserProfileInfo from "./UserProfileInfo/UserProfileInfo";
//import UserHackathons from "./UserHackathons/UserHackathons";
import axios from "axios";
import { API, BootcampBackend } from "../../../../../backend";
import { LoadingShape } from "react-rainbow-components";
import { loadImage } from "browser-image-compression";
import UserCalendarHeatmap from "./UserCalendarHeatmap/UserCalendarHeatmap.js";
import WorkExperience from "./WorkExperience/WorkExperience.js";
import HackathonUserInterviewDetails from "./HackathonUserInterviewDetails/HackathonUserInterviewDetails.js";
import HackathonUserDetails from "./HackathonUserDetails/HackathonUserDetails.js";
import { useHistory,useParams} from "react-router-dom";
import CreateScheduledInterview from "pages/PrivatePages/Interview/components/CreateScheduledInterview";
import moment from "moment";
export default function UserDetails(props) {
  const [applicantInfo, setApplicantInfo] = useState({});
  const [showContactList, setShowContactList] = useState(false);
  const [showAssessmentModal, setShowAssessmentModal] = useState(false);
  const [showInterviewModal, setShowInterviewModal] = useState(false);
  const [instantLink, setInstantLink] = useState("");
  const [interviewToken, setInterviewToken] = useState("");
  const [showAddNote,SetShowaddNote] = useState(false);
  const [notes,setNotes] = useState(props.userInfo.userNotes || []);
  const [selectedNote,setSelectedNote] = useState(null)
  const [note,setNote] = useState("");
  const history = useHistory();
  const ContactListRef = useRef();
  const { hackathonId } = useParams();
  const copyLink = () => {
    navigator.clipboard.writeText(`${applicantInfo?.Email}`);
  };

  const handleAssessmentModalSubmit = () => {
    if (props.assessmentModalData === "Add a New Assessment") {
      history.push("/assessments/create-question-set");
    } else if (props.assessmentModalData === "My Assessments") {
      props.setSelectedAssessment("own_assessment");
    } else if (props.assessmentModalData === "Add from MachineHack Library") {
      props.setSelectedAssessment("mh_assessment");
    }
    props.setAssessmentModalData("");
  };

  const handleAddNote = async () => {
    console.log(note,"note")
    const payload = {
      mhUserId: props?.uId,
      note: note,
    };
    try {
      const response = await axios.put(`${BootcampBackend}/v1/hackathons/${hackathonId}/notes`,payload ,{
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      });
      console.log(response.data.status.code,"response.data.result.data")
      if(response.data.status.code == 200){
        setNotes([...notes,note]);
        SetShowaddNote(false);
      }
  
    } catch (error) {
      console.error("Error updating note:", error.message);
    }
  }

  function generateScheduledLink() {
    const headers = {
      headers: {
        Authorization: JSON.parse(localStorage.getItem("jwt")).token,
      },
    };

    // Generate the scheduled link
    axios
      .get(
        `${BootcampBackend}/interview/generateInterviewLink/scheduled`,
        headers
      )
      .then((result) => {
        if (result.status == 200) {
          setInstantLink(result.data.result.url);
          setInterviewToken(result.data.result.token);
        } else {
          console.log("Error in fetching the API");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    const closeSalaryDropDown = (e) => {
      if (
        ContactListRef?.current &&
        !ContactListRef?.current?.contains(e.target)
      ) {
        setShowContactList(false);
      }
    };
    document.body.addEventListener("mousedown", closeSalaryDropDown);
  }, []);
  const getApplicantInfo = (userId) => {
    if (userId) {
      setApplicantInfo([]);
      axios
        .get(`${API}/user/newprofile/${userId}`, {
          params: {
            resume_key: true,
          },
        })
        .then((response) => {
          response.data.user.applicantRequiredAnswers = props.userInfo?.applicantRequiredAnswers;
          setApplicantInfo(response.data.user);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    props.type === "applicants"
      ? getApplicantInfo(props?.uId)
      : setApplicantInfo(props.userInfo);
    props.type === "applicants" && !props.userInfo?.viewed && props.ApplicantsView(props.userInfo)
  }, [props?.userInfo]);
  console.log(notes,"notesnotesnotes")
  return (
    <div
      className={`${(!props.isLoading && Object.keys(applicantInfo || {}).length > 0) ||
        props.isLoading
        ? "hackathonUserInfo col-md-8 mx-0"
        : ""
        }`}
    >
      <div
        className={`${(!props.isLoading && Object.keys(applicantInfo || {}).length > 0) ||
          props.isLoading
          ? "hackathonUserInfoHeader d-flex align-items-center justify-content-between"
          : ""
          }`}
      >
        <div className="d-flex">
          {props.isLoading ? (
            <LoadingShape
              className="me-2"
              style={{ width: "30%", height: "35px" }}
            />
          ) : (
            <>
              {Object.keys(applicantInfo || {}).length > 0 && (
                <div
                  onClick={() => history.push('/assessments')}
                  className="hackathonAssessBtn cursor-pointer me-3"
                >
                  Assess
                </div>
              )}
            </>
          )}
          {props.isLoading ? (
            <LoadingShape
              className="ms-4"
              style={{ width: "80%", height: "35px" }}
            />
          ) : (
            <>
              {Object.keys(applicantInfo || {}).length > 0 && (
                <div
                  onClick={() => {
                    setShowInterviewModal(true);
                    generateScheduledLink();
                  }}
                  className="hackathonStartInterview secondaryFilledBtn cursor-pointer "
                >
                  <span>Start Interview</span>
                  <img className="ms-2" src={startArrow} />
                </div>
              )}
              {props.hackathonNotes&&<div className="addNoteBtn ms-3 cursor-pointer" onClick={()=>SetShowaddNote(true)}><span>Add notes</span> <img  className="addNoteImg ms-2" src={addNote} alt="note" /></div>}
              <div className="ms-3 d-flex align-items-center">
              {applicantInfo?.Github != null && (
                  <img
                    onClick={() => {
                      window.open(`${applicantInfo?.Github}`,"_blank");
                    }}
                    className="mx-2"
                    src={GithubIcon}
                  />
                )}
                {applicantInfo?.Twitter != null && (
                  <img
                    onClick={() => {
                      window.open(`${applicantInfo?.Twitter}`,"_blank");
                    }}
                    className="mx-2"
                    src={TwitterIcon}
                  />
                )}
                {(applicantInfo?.Linkedin  || applicantInfo.LinkedIn )&& (
                  <img
                    onClick={() => {
                      window.open(`${applicantInfo?.Linkedin  || applicantInfo.LinkedIn}`,"_blank");
                    }}
                    className="mx-2"
                    src={LinkedinIcon}
                  />
                )}
              </div>
            </>
          )}
        </div>
        {props.isLoading ? (
          <LoadingShape
            className="me-2"
            style={{ width: "30%", height: "35px" }}
          />
        ) : (
          <>
            {!props.isLoading && Object.keys(applicantInfo || {}).length > 0 && (
              <div className="participantContact">
                <span
                  className="participantDropdown"
                  ref={ContactListRef}
                  onClick={() => {
                    setShowContactList(!showContactList);
                  }}
                >
                  Contact
                  {showContactList && (
                    <div className="participantDropdownList">
                      <span
                        onClick={() => {
                          window.open(
                            "https://machinehack.com/user/" +
                            props?.uId
                          );
                        }}
                      >
                        <img className="me-4 " src={contactProfile} />
                        See MH Profile
                      </span>
                      {applicantInfo?.Email && (
                        <span
                          className="d-flex"
                          onClick={() => {
                            copyLink();
                          }}
                        >
                          <img className="me-3 " src={contactEmail} />
                          {applicantInfo?.Email}
                          <img className="ms-3" src={profileEmailCopy} />
                        </span>
                      )}
                      {applicantInfo?.Mobile && (
                        <span>
                          <img className="me-3 ms-1" src={contactPhone} />
                          +91 {applicantInfo?.Mobile}
                        </span>
                      )}
                    </div>
                  )}
                </span>
                <img className="ms-2 me-4" src={dropdownArrow} />
              </div>
            )}
          </>
        )}
      </div>
      <div
        className={`${(!props.isLoading && Object.keys(applicantInfo || {}).length > 0) ||
          props.isLoading
          ? "hackathonScrollData"
          : ""
          }`}
      >
        <HackathonUserDetails
          isLoading={props.isLoading}
          isLeaderBoardLoading={props.isLeaderBoardLoading}
          userInfo={applicantInfo}
        />
        <HackathonUserInterviewDetails
          selectedUserRank={props.selectedUserRank}
          isLoading={props.isLoading}
          isLeaderBoardLoading={props.isLeaderBoardLoading}
          userInfo={applicantInfo}
          notebookData={props.notebookData}
          uId={props?.uId}
        />
        <div className="mt-3 px-4">
        <p className="noteHeading mb-3">Notes ({notes.length})</p>
          {notes.map((ele)=>  <div className="noteHld px-2 row"><div className="col-11"><div className="noteTimeHld">{moment(ele.createdAt).format('ll')}</div><p className={`pt-1 pb-2 textThin ${selectedNote === ele._id?``:`text-truncate`}`}>{ele.note}</p></div> <div className="noteArrowHld col-1 px-0 cursor-pointer d-flex align-items-center justify-content-center" onClick={()=>setSelectedNote(selectedNote===ele._id?null:ele._id)}><img className="" src={dropdownArrow} /></div> </div>)}
        </div>
        {props.isLoading ? (
          <div className="mx-3">
            <LoadingShape
              className="my-4"
              style={{ width: "20%", height: "20px" }}
            />
            <LoadingShape className="my-2" style={{ height: "14px" }} />
            <LoadingShape className="my-2" style={{ height: "14px" }} />
            <LoadingShape className="my-2" style={{ height: "14px" }} />
            <LoadingShape className="my-2" style={{ height: "14px" }} />
            <LoadingShape className="my-2" style={{ height: "14px" }} />
          </div>
        ) : (
          applicantInfo?.Bio && (
            <div className="mx-3 mt-3">
              <span className="cardHeading">About</span>
              <div
                className="mt-2 paragraph me-5"
                dangerouslySetInnerHTML={{ __html: applicantInfo?.Bio }}
              ></div>
            </div>
          )
        )}
        <WorkExperience
          isLoading={props.isLoading}
          isLeaderBoardLoading={props.isLeaderBoardLoading}
          resume={applicantInfo?.Resume_key}
        />
        <div className="hackathonEducation mt-4">
          {applicantInfo?.applicantRequiredAnswers?.map((question) => (
            <div className="questionsAnsHld d-flex mb-3">
              <div className="mx-3">
                <span
                  className={`${question.answer !== ""
                    ? "answeredQuestion"
                    : "notansweredQuestion"
                    }`}
                >
                  <img
                    src={question.answer !== "" ? greenCheck : greyCheck}
                    alt="check"
                    className="img-fluid"
                  />
                </span>
              </div>
              <div className="col-10">
                <p className="mb-0 question">{question?.question}</p>
                <p className="mb-0 answer">{question?.answer}</p>
              </div>
            </div>
          ))}
        </div>
        <Modal
          centered={true}
          show={showAddNote}
          className="requestHackathonModal my-auto"
          onHide={() => setNote("")}
        >
          <Modal.Header className="modalNoteHeader">
            <div className="addNoteHeader"><img
              src={Note}
              alt="note"
            /><span className="ms-2">Add Notes</span></div>
            <div className="addNoteCloseHld">
            <img
              className="cursor-pointer"
              style={{filter:"invert(1)"}}
              onClick={() => SetShowaddNote(false)}
              src={closeModal}
            />
            </div>
          </Modal.Header>
          <hr className="requestHackathonModalHeaderDiv" />
          <Modal.Body className="p-0">
            <div className="py-2 px-3">
              <textarea
                onChange={(e)=>setNote(e.target.value)}
                name="note"
                className="addNoteInput"
                placeholder="Add your quick notes..."
                rows="4"
                cols="50"
              />
            </div>
            <div className="addNoteSubmitBtn cursor-pointer" onClick={handleAddNote}>Submit <img className="ms-2" src={startArrow} alt="arrow"/> </div>
          </Modal.Body>
        </Modal>
        <Modal
          centered={true}
          show={showAssessmentModal}
          className="requestHackathonModal my-auto"
          onHide={() => { }}
        >
          <Modal.Header className="position-relative modalJobHeader">
            <img
              className="requestHackathonModalClose cursor-pointer"
              onClick={() => setShowAssessmentModal(false)}
              src={closeModal}
            />
            <div className="mx-auto Header-heading">Assessment</div>
          </Modal.Header>
          <hr className="requestHackathonModalHeaderDiv" />
          <Modal.Body>
            <div className="assessmentModal">
              <div
                onClick={() =>
                  props.setAssessmentModalData("Add a New Assessment")
                }
                className={`cursor-pointer ${props.assessmentModalData === "Add a New Assessment"
                  ? "assessmentModalDataSelected"
                  : "assessmentModalData"
                  }`}
              >
                <img className="me-3" src={AddAssessment} />
                <span>Add a New Assessment</span>
              </div>
              <div
                onClick={() => props.setAssessmentModalData("My Assessments")}
                className={`cursor-pointer ${props.assessmentModalData === "My Assessments"
                  ? "assessmentModalDataSelected"
                  : "assessmentModalData"
                  }`}
              >
                <img className="me-3" src={MyAssessment} />
                <span>My Assessments</span>
              </div>
              <div
                onClick={() =>
                  props.setAssessmentModalData("Add from MachineHack Library")
                }
                className={`cursor-pointer ${props.assessmentModalData === "Add from MachineHack Library"
                  ? "assessmentModalDataSelected"
                  : "assessmentModalData"
                  }`}
              >
                <img className="me-3" src={Library} />
                <span>Add from MachineHack Library</span>
              </div>
              <div
                className={`mx-auto cursor-pointer ${props.assessmentModalData !== ""
                  ? "primaryBtnActive"
                  : "primaryBtnInactive"
                  }  mt-5 assessmentModalSubmit`}
                onClick={() => handleAssessmentModalSubmit()}
              >
                Submit
                <img className="ms-3" src={submitArrow} />
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div />
      </div>
      <CreateScheduledInterview
        showInterviewModal={showInterviewModal}
        setShowInterviewModal={setShowInterviewModal}
        instantLink={instantLink}
        applicantInfo={applicantInfo}
        interviewToken={interviewToken}
      />
    </div>
  );
}
