//CSS
import "./Dashboard.css";
//LOGO and ICONS
import BallonsImage from "../../../assets/Icons/Dashboard/ballons-image.svg";
import MarkIcon from "../../../assets/Icons/Dashboard/tick-Icon.svg";

//COMPONENTS
import Header from "../../../components/Header/Header";
import Navbar from "../../../components/Navbar/Navbar";
//LIB
import SwiperCore, { Navigation } from "swiper/core";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import axios from "axios";
import React, { useState, useEffect } from "react";
import {API, BootcampBackend } from "../../../backend";
import { useHistory } from "react-router-dom";
import { LoadingShape } from "react-rainbow-components";
import HackathonStatistics from "./Sections/HackathonStatistics";
import InterviewStatistics from "./Sections/InterviewStatistics";
import YourHackthons from "./Sections/YourHackthons";
import YourJobs from "./Sections/YourJobs";
import Assessment from "./Sections/Assessment";
import Schedule from "./Sections/Schedule";
import Users from "./Sections/Users";
import NoAssessment from "./Sections/NoAssessment";
import NoSchedule from "./Sections/NoSchedule";
import NoJobs from "./Sections/NoJobs";
import NoUsers from "./Sections/NoUsers";
import NoHackthons from "./Sections/NoHackthons";
import NoHackathonStatistics from "./Sections/NoHackathonStatistics";
import { useSelector } from "react-redux";
import SubscriptionPlanScreen from "../PricingPages/SubscriptionPlanScreen";
import { isAuthenticated } from "helper/user";
import ContactUs from "components/ContactUs/ContactUs";
import { useSidebar } from "helper/SideBarContext";

SwiperCore.use([Navigation]);

export default function NewDashBoard() {
  const [hackathons, setHackathons] = useState({});
  const [interviews, setInterviews] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [expeirenceData, setExpeirenceData] = useState([]);
  const [yourHackathons, setYourHackathons] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [myAssessments, setMyAssessments] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [usersTableData, setUsersTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { isSidebarCollapsed } = useSidebar();
  const [showPlan, setShowPlan] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const subscriptionData = useSelector((state) => state.subscription);

  useEffect(() => {
    // dashboard added for basic plan 
    // if (subscriptionData?.isLoaded) {
      // if (
      //   isAuthenticated()?.role !== "admin_panel" &&
      //   subscriptionData?.subscriptionData?.mh_subscription_plan_id?.name !==
      //     "professional"
      // ) {
      //   setShowPlan(!showPlan);
      //   return;
      // }
      getInterviewStatistics();
      fetchHackthonStatistics();
      fetchYourHackthonsData();
      fetchJobsList();
      getMyAssessments();
      fetchScheduleData();
      fetchIamUserData();
    // }
  }, [subscriptionData?.isLoaded]);

  async function getInterviewStatistics() {
    await axios
      .get(`${BootcampBackend}/interview/getInterviewStats`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setInterviews(res.data.result.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }
  async function fetchHackthonStatistics() {
    try {
      const res = await axios.get(`${BootcampBackend}/v1/hackathons/statistics/all`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("jwt")).token
          }`,
        },
      });
  
      if (res?.data) {
        setLocationData(res.data?.result?.hackathonStatistics?.cityDistribution);
        setExpeirenceData(res.data?.result?.hackathonStatistics?.experienceDistribution);
      }
    } catch (err) {
      setIsLoading(false);
      console.error("Error fetching hackathon statistics:", err);
    }
  }
  

  async function fetchYourHackthonsData() {
    try {
      const res = await axios.get(`${BootcampBackend}/v1/hackathons`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      });
  
      if (res?.data?.result) {
        setYourHackathons(res.data.result.hackathonData);
      }
      setIsLoading(false);
  
      return res;
    } catch (err) {
      setIsLoading(false);
      console.error("Error fetching your hackathons data:", err);
    }
  }
  

  async function fetchJobsList() {
    try {
      const res = await axios
        .get(`${BootcampBackend}/jobs/getJobForCompany/false`, {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        })
        .then((res) => {
          setJobList(res.data.result.data);
        });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  async function getMyAssessments() {
    const paramData = {
      limit: 10,
      current_page: 1,
    };
    await axios
      .get(
        `${BootcampBackend}/enterpriseAssessment/getAllAssessmentsUnderCompany`,
        {
          params: paramData,
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        setMyAssessments(res?.data?.result?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }

  async function fetchScheduleData() {
    try {
      const res = await axios
        .get(`${BootcampBackend}/interview/getTodayInterview`, {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        })
        .then((res) => {
          setTableData(res.data.result.data);
          setIsLoading(false);
        });
      return res;
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  async function fetchIamUserData() {
    const res = await axios
      .get(`${BootcampBackend}/iamUser/all`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      })
      .then((res) => {
        setUsersTableData(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  var myDate = new Date();
  var hrs = myDate.getHours();

  var greet;

  if (hrs < 12) greet = "Good Morning";
  else if (hrs >= 12 && hrs <= 17) greet = "Good Afternoon";
  else if (hrs >= 17 && hrs <= 24) greet = "Good Evening";

  return (
    <div>
      <Header />
      <div className="container-fluid body-margin-top px-0">
        <div className="dashboard">
          <Navbar />
          {isLoading ? (
            <>
              <div
                className={`mt-4 px-3 pt-2 ${
                  isSidebarCollapsed ? "collapsed-content-page" : "content-page"
                }`}
              >
                <LoadingShape className="dashBoardLoaderCardTitle my-3" />

                <div className="dashBoardLoaderCard mb-3 flex-wrap">
                  <div className="col-9">
                    <LoadingShape className="dashBoardLoaderCardDes" />
                  </div>
                  <div className="ms-auto d-flex col-3">
                    <LoadingShape className="mx-2" />
                    <LoadingShape className="mx-2" />
                  </div>
                  <div className="mt-2 p-3 d-flex col-12 flex-wrap">
                    {Array.from(
                      {
                        length: 5,
                      },
                      (_, i) => (
                        <div
                          className="dashboardInterviewApplications col-2 me-4 ms-2 mb-2 p-3"
                          key={i}
                        >
                          <LoadingShape className="dashBoardLoaderCardDivTitle" />
                          <LoadingShape className="dashBoardLoaderCardDes mt-4" />
                          <LoadingShape className="dashBoardLoaderCardDes mt-2" />
                        </div>
                      )
                    )}
                  </div>
                </div>

                {Array.from(
                  {
                    length: 2,
                  },
                  (_, i) => (
                    <div
                      className="dashBoardLoaderCard mb-3 col-12 flex-wrap"
                      key={i}
                    >
                      <div className="col-8">
                        <LoadingShape className="dashBoardLoaderCardDes" />
                      </div>
                      <div className="ms-auto d-flex col-3">
                        <LoadingShape className="mx-2" />
                        <LoadingShape className="mx-2" />
                      </div>
                      <div className="mt-2 p-3 d-flex col-12 flex-wrap justify-content-between">
                        {Array.from(
                          {
                            length: 2,
                          },
                          (_, i) => (
                            <div
                              className="dashboardInterviewApplications col-5 me-4 mb-2 p-3"
                              key={i}
                            >
                              <LoadingShape className="dashBoardLoaderCardDivTitle" />
                              <LoadingShape className="dashBoardLoaderCardDes my-3" />
                              <LoadingShape className="dashBoardLoaderCardDivTitle " />
                              <LoadingShape className="dashBoardLoaderCardDes mt-2" />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
              <div></div>
            </>
          ) : (
            <div
              className={`px-3 ${
                isSidebarCollapsed ? "collapsed-content-page" : "content-page"
              }`}
            >
              {expeirenceData?.length > 0 ||
              locationData?.length > 0 ||
              yourHackathons?.length > 0 ||
              jobList?.length > 0 ||
              myAssessments?.length > 0 ||
              tableData?.length > 0 ||
              usersTableData?.length > 1 ? (
                <>
                  <div className="dashboardTotalSearchBar px-4"></div>
                  <div className="dashboardScroller px-3">
                    {Object.keys(interviews)?.length !== 0 ? (
                      <>
                        <InterviewStatistics
                          interviews={interviews}
                          isLoading={isLoading}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    <div className="row">
                      {yourHackathons.length !== 0 ? (
                        <YourHackthons
                          yourHackathons={yourHackathons}
                          isLoading={isLoading}
                        />
                      ) : (
                        <NoHackthons />
                      )}
                      {(expeirenceData&& locationData)?(
                        <HackathonStatistics
                          hackathons={hackathons}
                          isLoading={isLoading}
                          expeirenceData={expeirenceData}
                          locationData={locationData}
                        />
                      ) : (
                        <NoHackathonStatistics />
                      )}
                    </div>
                    <div className="row">
                      {" "}
                      {jobList?.length > 0 ? (
                        <YourJobs jobList={jobList} isLoading={isLoading} />
                      ) : (
                        <NoJobs jobList={jobList} isLoading={isLoading} />
                      )}
                      {myAssessments?.length > 0 ? (
                        <Assessment
                          myAssessments={myAssessments}
                          isLoading={isLoading}
                        />
                      ) : (
                        <NoAssessment
                          myAssessments={myAssessments}
                          isLoading={isLoading}
                        />
                      )}
                    </div>
                    <div className="row">
                      {tableData?.length > 0 ? (
                        <Schedule tableData={tableData} isLoading={isLoading} />
                      ) : (
                        <NoSchedule
                          tableData={tableData}
                          isLoading={isLoading}
                        />
                      )}
                    </div>
                    {usersTableData?.length > 1 ? (
                      <Users
                        usersTableData={usersTableData}
                        isLoading={isLoading}
                      />
                    ) : (
                      <NoUsers
                        usersTableData={usersTableData}
                        isLoading={isLoading}
                      />
                    )}
                  </div>
                </>
              ) : (
                <div className="row">
                  <div className=" col-lg-12 text-center dashboardHeadingContainer">
                    <h2>
                      {greet} {user?.firstName}!
                    </h2>
                  </div>
                  <img
                    src={BallonsImage}
                    alt="ballons-image"
                    className="img-fluid ballons-image"
                  />
                  <div>
                    <p className="text-center dashboardUserName">
                      Hello {user?.firstName}, Welcome to MachineHack
                    </p>
                  </div>
                  <div className="text-center dashboardContent">
                    <h3>Start setting up your Account now</h3>
                  </div>

                  <div className="dashboardTotalContentBox">
                    <div
                      className="dashboardConetentBox"
                      onClick={() => {
                        history.push("/settings/iamUser");
                      }}
                    >
                      <img
                        className="dashboardMarkIcon"
                        src={MarkIcon}
                        alt="data"
                      />
                      <p className="dashboardContentBoxData">Add IAM user</p>
                    </div>
                    <div
                      className="dashboardConetentBox"
                      onClick={() => {
                        history.push("/myHackathons");
                      }}
                    >
                      <img
                        className="dashboardMarkIcon"
                        src={MarkIcon}
                        alt="data"
                      />
                      <p className="dashboardContentBoxData">Host Hackathon</p>
                    </div>
                    <div
                      className="dashboardConetentBox"
                      onClick={() => {
                        history.push("/jobs");
                      }}
                    >
                      <img
                        className="dashboardMarkIcon"
                        src={MarkIcon}
                        alt="data"
                      />
                      <p className="dashboardContentBoxData">Post your Jobs</p>
                    </div>
                    <div
                      className="dashboardConetentBox"
                      onClick={() => {
                        history.push("/assessments");
                      }}
                    >
                      <img
                        className="dashboardMarkIcon"
                        src={MarkIcon}
                        alt="data"
                      />
                      <p className="dashboardContentBoxData">Assessments</p>
                    </div>
                    <div
                      className="dashboardConetentBox"
                      onClick={() => {
                        history.push("/interviews");
                      }}
                    >
                      <img
                        className="dashboardMarkIcon"
                        src={MarkIcon}
                        alt="data"
                      />
                      <p className="dashboardContentBoxData">
                        Start Interviewing Candidates
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {/* <SubscriptionPlanScreen
        showPlan={showPlan}
        setShowPlan={setShowPlan}
        showContactUs={showContactUs}
        setShowContactUs={setShowContactUs}
        titleType="feature"
      /> */}
      <ContactUs
        showContactUs={showContactUs}
        setShowContactUs={setShowContactUs}
        type="pricing"
      />
    </div>
  );
}
