import React, {useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getUserRole, isAuthenticated } from "../../helper/user";
import Logo from "../../assets/Logos/MH_Logo.svg";
import WelcomeUserPlanScreen from "pages/PrivatePages/PricingPages/WelcomeUserPlanScreen";
import SubscriptionPlanScreen from "pages/PrivatePages/PricingPages/SubscriptionPlanScreen";
import UserPlanPopup from "pages/PrivatePages/PricingPages/UserPlanPopup";
import { useSelector } from "react-redux";
import ContactUs from "components/ContactUs/ContactUs";
import { useSidebar } from "helper/SideBarContext";
//CSS
import "./Navbar.css";
//LOGO and ICONS
import dashboardIconInactive from "assets/Icons/Navbar/dashboard.svg";
import myHackathonIconsInactive from "assets/Icons/Navbar/hackathons.svg";
import settingIconInactive from "assets/Icons/Navbar/settings.svg";
import jobIconInactive from "assets/Icons/Navbar/jobs.svg";
import assesssmentsInactive from "assets/Icons/Navbar/assessments.svg";
import inactiveadminIcon from "assets/Icons/Assessments/AdminPannel.svg";
import interviewInactive from "assets/Icons/Navbar/interviews.svg";
import chevronImg from "assets/Icons/Header/downChevron.svg";
import contactUs from "assets/Icons/DashboardNew/contactUs.svg";
import PremiumIcon from "assets/Icons/Pricing/crown.svg";
import CloseIcon from "assets/Icons/Navbar/shortMenu.svg";
import lmsIcon from "assets/Icons/Navbar/lms.svg"
const getNavItems = (showApiTab) => {
  const navItems = [
    {
      id: "1",
      name: "Dashboard",
      inactiveIcon: dashboardIconInactive,
      route: "dashboard",
      slug: "dashboard",
    },
    {
      id: "2",
      name: "Hackathons",
      inactiveIcon: myHackathonIconsInactive,
      route: "myHackathons",
      slug: "hackathons",
    },
    {
      id: "3",
      name: "Jobs",
      inactiveIcon: jobIconInactive,
      route: "jobs",
      slug: "jobs",
    },
    {
      id: "4",
      name: "Assessments",
      inactiveIcon: assesssmentsInactive,

      route: "assessments",
      slug: "assessments",
    },
    {
      id: "5",
      name: "Interviews",
      inactiveIcon: interviewInactive,
      route: "interviews",
      slug: "interviews",
    },
    {
      id: "6",
      name: "LMS",
      inactiveIcon: lmsIcon,
      route: "lms",
      slug: "lms",
    },
    // {
    //   id: "6",
    //   name: "Resume Search",
    //   activeIcon: searchTabActive,
    //   inactiveIcon: dashboardIconInactive,
    //   route: "resumeSearch",
    //   slug: "resumeSearch",
    // },
    {
      id: "7",
      name: "Settings",
      inactiveIcon: settingIconInactive,
      route: "settings",
      showdropdown: false,
      slug: "settings",
      menuItems: [
        {
          id: "1",
          name: "Profile",
          route: "/settings/editProfile",
          show: true,
        },
        {
          id: "2",
          name: "Users",
          route: "/settings/iamUser",
          show: true,
        },
        {
          id: "3",
          name: "API keys",
          route: "/settings/apikeys",
          show: showApiTab,
        },
        {
          id: "4",
          name: "My Subscripions",
          route: "/settings/my-subscriptions",
          show: true,
        },
      ],
    },
    {
      id: "8",
      name: "Admin",
      inactiveIcon: inactiveadminIcon,
      route: "admin",
      showdropdown: false,
      slug: "admin",
      menuItems: [
        {
          id: "1",
          name: "Companies",
          route: "/admin/companies",
          show: true,
        },
        {
          id: "2",
          name: "Jobs",
          route: "/admin/jobs",
          show: true,
        },
        {
          id: "3",
          name: "Interviews",
          route: "/admin/interviews",
          show: true,
        },
        {
          id: "4",
          name: "Assessments",
          route: "/admin/assessments",
          show: true,
        },
        {
          id: "5",
          name: "Subscription Plans",
          route: "/admin/plans",
          show: true,
        },
        {
          id: "6",
          name: "Invoices",
          route: "/admin/invoices",
          show: true,
        },
      ],
    },
  ];
  return navItems;
};

export default function Navbar(props) {
  const subscription = useSelector((state) => state.subscription);
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const [navItems, setNavItems] = useState(() => getNavItems(props.showApiTab));
  const [showPlan, setShowPlan] = useState(false);
  const [showCurrentPlan, setShowCurrentPlan] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const { pathname } = location;
  const [showUserPlanWelcomeScreen, setShowUserPlanWelcomeScreen] =
    useState(false);
  const [popupOriginType, setPopupOriginType] = useState("");
  const history = useHistory();
  const { isSidebarCollapsed, toggleSidebar } = useSidebar();
  const splitLocation = pathname.split("/");

  function redirectToMachineHack() {
    window.open("https://machinehack.com", "_blank");
  }
  const redirectToProfile = () => {
    history.push("/settings/editProfile");
  };
  const showAdminPanelForAdmins = (item) => {
    let showMenu = false;
    if (item.name === "Admin") {
      getUserRole() === "admin_panel" ? (showMenu = true) : (showMenu = false);
    } else {
      showMenu = true;
    }
    return showMenu;
  };
  const onSideNavChange = (item) => {
    if (item?.menuItems?.length > 0) {
      setNavItems((prev) =>
        prev.filter((x) => {
          if (x.name === item.name) {
            x.showdropdown = !x.showdropdown;
          } else {
            x.showdropdown = false;
          }
          return prev;
        })
      );
      item.name === "Admin"
        ? history.push(`/${item?.route}/companies`)
        : history.push(`/${item?.route}/editProfile`);
    } else {
      setNavItems((prev) =>
        prev.filter((x) => {
          if (x.name === "Admin") {
            x.showdropdown = false;
          }
          return prev;
        })
      );
      // dashboard added for basic plan 
      // if (
      //   item.slug === "dashboard" &&
      //   isAuthenticated()?.role !== "admin_panel"
      // ) {
      //   if (
      //     item.slug === "dashboard" &&
      //     subscription.subscriptionData?.mh_subscription_plan_id?.name ===
      //       "professional"
      //   ) {
      //     history.push(`/${item?.route}`);
      //   } else {
      //     subscription.subscriptionData?._id
      //       ? setShowCurrentPlan(!showCurrentPlan)
      //       : setShowPlan(!showPlan);
      //   }
      // } else 
      if(item.slug === "resumeSearch" && isAuthenticated()?.role !== "admin_panel"){
        if (subscription.subscriptionData?._id) {
          history.push(`/${item?.route}`);
        } else {
          setShowPlan(!showPlan);
        }
      } else {
        history.push(`/${item?.route}`);
      }
    }
  };

  useEffect(() => {
    if (splitLocation[1] === "admin" || splitLocation[1] === "settings") {
      setNavItems((prev) =>
        prev.filter((x) => {
          if (x.name?.toLowerCase() === splitLocation[1]) {
            x.showdropdown = true;
          }
          return prev;
        })
      );
    }
    const isFirstLogin = localStorage.getItem("firstlogin");
    if (isFirstLogin === "true") {
      setShowUserPlanWelcomeScreen(!showUserPlanWelcomeScreen);
      localStorage.removeItem("firstlogin");
    }
  }, []);

  useEffect(() => {
    if(props.showApiTab){
      setNavItems(prev => prev.filter(x => {
        if(x.route.includes('settings')){
          x.menuItems.filter(x => {
            x.show = props.showApiTab;
          })
          return x.menuItems 
        }
        return x.route
      }))
    }
  },[props.showApiTab])
  
  return (
    <>
      <div
        className={`${
          !isSidebarCollapsed
            ? "sideNav d-flex flex-column"
            : "sideNavCollapsed"
        }`}
        id="collapse"
      >
        <div className="companylogohld d-flex justify-content-between align-items-center px-2 mb-2 mt-1">
          {(!isSidebarCollapsed) &&
            (user?.company?.logo ? (
              <img
                onClick={redirectToProfile}
                className="company-logo"
                src={user?.company?.logo}
                alt="logo"
              />
            ) : (
              <div className="company-logo"></div>
            ))}
          {isSidebarCollapsed ? (
            <img
              src={CloseIcon}
              alt="close"
              className="img-fluid ms-2 cursor-pointer"
              onClick={toggleSidebar}
            />
          ) : (
            <></>
          )}
        </div>
        <Nav
          className="pb-3 navItemsHld"
        >
          {navItems.map((item) => (
            <React.Fragment key={item.id}>
              {showAdminPanelForAdmins(item) && (
                <div
                  className={`navLinks`}
                  onClick={() => {
                    localStorage.removeItem("adminView");
                  }}
                >
                  <div
                    className={`custom-styling  ${
                      splitLocation[1] === item?.route ? "navItemActive iconTowhite" : "navItemInactive"
                    }`}
                    onClick={() => onSideNavChange(item)}
                  >
                    <img
                      className="sideBarIcons"
                      alt={splitLocation[1]}
                      src={item?.inactiveIcon}
                    />
                    {(!isSidebarCollapsed) && (
                      <>
                        <p
                          className={`labelRemove ${
                            splitLocation[1] === item?.route
                              ? "sideBarMenuActive"
                              : ""
                          } sideBarMenu`}
                        >
                          {item?.name}{" "}
                          {item?.route === "assessments" &&
                          isAuthenticated()?.role !== "admin_panel" &&
                          subscription?.subscriptionData
                            ?.mh_subscription_plan_id?.name !== "professional"
                            ? `(${
                                subscription?.totalCreatedAssessmentCount >
                                (subscription?.subscriptionData
                                  ?.mh_subscription_plan_id?.assessmentCount ||
                                  1)
                                  ? subscription?.subscriptionData
                                      ?.mh_subscription_plan_id
                                      ?.assessmentCount || 1
                                  : subscription?.totalCreatedAssessmentCount
                              }/${
                                subscription?.subscriptionData
                                  ?.mh_subscription_plan_id?.assessmentCount ||
                                1
                              })`
                            : ""}
                          {item?.route === "interviews" &&
                          isAuthenticated()?.role !== "admin_panel" &&
                          subscription?.subscriptionData
                            ?.mh_subscription_plan_id?.name !== "professional"
                            ? `(${
                                subscription?.totalCreatedInterviewCount >
                                (subscription?.subscriptionData
                                  ?.mh_subscription_plan_id
                                  ?.liveInterviewCount || 1)
                                  ? subscription?.subscriptionData
                                      ?.mh_subscription_plan_id
                                      ?.liveInterviewCount || 1
                                  : subscription?.totalCreatedInterviewCount
                              }/${
                                subscription?.subscriptionData
                                  ?.mh_subscription_plan_id
                                  ?.liveInterviewCount || 1
                              })`
                            : ""}
                        </p>
                        {item?.menuItems?.length > 0 ? (
                          <img
                            src={chevronImg}
                            alt=""
                            className="img-fluid"
                            width="13"
                            height="13"
                          />
                        ) : (
                          <span
                            style={{ width: "10px", height: "13px" }}
                          ></span>
                        )}
                        {(item?.slug === "dashboard" && isAuthenticated()?.role !== "admin_panel" &&
                          subscription.subscriptionData?.mh_subscription_plan_id
                            ?.name !== "professional") ||
                        (item.slug === "resumeSearch" && isAuthenticated()?.role !== "admin_panel" &&
                          !subscription.subscriptionData?._id) ? (
                          <img
                            src={PremiumIcon}
                            alt="premium"
                            className="img-fluid premium-user-icon me-2"
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                  {item?.showdropdown && !isSidebarCollapsed && (
                    <ul>
                      {item?.menuItems?.map((menu) => (
                        <React.Fragment key={menu.id}>
                          {menu.show ? (
                            <li
                              key={menu.id}
                              onClick={() => history.push(menu.route)}
                              style={{
                                color: `${
                                  pathname === menu.route ? "#f44d5e" : ""
                                }`,
                              }}
                            >
                              {menu?.name}
                            </li>
                          ) : (
                            <></>
                          )}
                        </React.Fragment>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </React.Fragment>
          ))}
          {(!isSidebarCollapsed) && (
            <p
              className="user-plan-name cursor-pointer text-capitalize"
              onClick={() => setShowPlan(!showPlan)}
            >
              {subscription?.subscriptionData?.mh_subscription_plan_id?.name ||
                "Starter"}{" "}
              Plan
            </p>
          )}
        </Nav>
        {(!isSidebarCollapsed) && (
          <div
            className="navBottom mt-auto"
          >
            <div
              className="navContactUsHld cursor-pointer"
              onClick={() => {
                setPopupOriginType("default");
                setShowContactUs(true);
              }}
            >
              <span className="me-1">Contact us</span>{" "}
              <img src={contactUs} alt="contact us" />
            </div>
            <div className="d-flex justify-content-center ">
              <img
                onClick={redirectToMachineHack}
                className="machinehack-logo py-2"
                src={Logo}
                alt="logo"
              />
            </div>
          </div>
        )}
      </div>
      <WelcomeUserPlanScreen
        showUserPlanWelcomeScreen={showUserPlanWelcomeScreen}
        setShowUserPlanWelcomeScreen={setShowUserPlanWelcomeScreen}
      />
      <SubscriptionPlanScreen
        showPlan={showPlan}
        setShowPlan={setShowPlan}
        showContactUs={showContactUs}
        setShowContactUs={setShowContactUs}
        setPopupOriginType={setPopupOriginType}
        titleType="generic"
      />
      <UserPlanPopup
        showCurrentPlan={showCurrentPlan}
        setShowCurrentPlan={setShowCurrentPlan}
        subscriptionData={subscription.subscriptionData}
        setShowPlan={setShowPlan}
        showPlan={showPlan}
      />
      <ContactUs
        showContactUs={showContactUs}
        setShowContactUs={setShowContactUs}
        type={popupOriginType}
      />
    </>
  );
}
