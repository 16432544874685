import React, { useEffect, useState, useRef } from "react";
import hackathoneRanking1 from "../../assets/Images/MyHackathons/hackathoneRanking1.svg";
import rankingLogo2 from "../../assets/Icons/MyHackathons/rankingLogo2.svg";
import { LoadingShape, Pagination } from "react-rainbow-components";
import rankingLogo3 from "../../assets/Icons/MyHackathons/rankingLogo3.svg";
import threeDots from "../../assets/Icons/Jobs/dotsThree.svg";
import selectIcon from "../../assets/Icons/Jobs/select.svg";
import RejectedIcon from "../../assets/Icons/Jobs/reject.svg";
import saveIcon from "../../assets/Icons/Jobs/Shortlist.svg";
import moveToAll from "../../assets/Icons/Jobs/moveToAll.svg";
import ShortListActive from "assets/Images/MyHackathons/shortlistActive.png"
import ShortListInactive from "assets/Images/MyHackathons/shortlistInactive.png"
import axios from "axios";
import { BootcampBackend } from "../../backend";
import "./index.css";
function CandidatesList(props) {
  const { applicantId } = props;
  const userData =
    props.type === "jobApplicants"
      ? props.applicantData
      : props.leaderboardUsers;
  const [applicantOptions, setApplicantOptions] = useState("");
  const updateApplicantStatus = async (statusType, revertType, data) => {
    await axios
      .put(
        `${BootcampBackend}/jobs/${statusType}${
          revertType !== "" ? `?selectionType=${revertType}` : ""
        }`,
        {
          timeStamp: Date(),
          applicantId: applicantId.applicantId?._id,
          jobId: props.jobId,
          applicantRequiredAnswers: data.applicantRequiredAnswers,
          views: data.views,
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt"))?.token,
          },
        }
      )
      .then(() => {
        props.updateCandidatesList(
          userData.filter((x) => {
            if (x._id === data._id) {
              return x._id !== data._id;
            }
            return userData;
          })
        );
        const selectedData = userData.filter((x) => {
          if (x._id === data._id) {
            return x._id !== data._id;
          }
          return userData[0];
        });
        props.SetApplicantId(selectedData[0]);
        if (props.applicantTabs === "all") {
          statusType === "shortListApplicant"
            ? props.updateJobs((prev) => ({
                ...prev,
                applicants: prev.applicants > 0 ? prev.applicants - 1 : 0,
                shortlistedApplicants: prev.shortlistedApplicants + 1,
              }))
            : statusType === "saveApplicant"
            ? props.updateJobs((prev) => ({
                ...prev,
                applicants: prev.applicants > 0 ? prev.applicants - 1 : 0,
                savedApplicants: prev.savedApplicants + 1,
              }))
            : statusType === "rejectApplicant"
            ? props.updateJobs((prev) => ({
                ...prev,
                applicants: prev.applicants > 0 ? prev.applicants - 1 : 0,
                rejectedApplicants: prev.rejectedApplicants + 1,
              }))
            : "";
        } else if (props.applicantTabs === "shortlisted") {
          statusType === "saveApplicant"
            ? props.updateJobs((prev) => ({
                ...prev,
                savedApplicants: prev.savedApplicants + 1,
                shortlistedApplicants:
                  prev.shortlistedApplicants > 0
                    ? prev.shortlistedApplicants - 1
                    : 0,
              }))
            : statusType === "rejectApplicant"
            ? props.updateJobs((prev) => ({
                ...prev,
                shortlistedApplicants:
                  prev.shortlistedApplicants > 0
                    ? prev.shortlistedApplicants - 1
                    : 0,
                rejectedApplicants: prev.rejectedApplicants + 1,
              }))
            : statusType === "shortListApplicant"
            ? props.updateJobs((prev) => ({
                ...prev,
                shortlistedApplicants:
                  prev.shortlistedApplicants > 0
                    ? prev.shortlistedApplicants - 1
                    : 0,
                applicants: prev.applicants + 1,
              }))
            : "";
        } else if (props.applicantTabs === "selected") {
          statusType === "rejectApplicant"
            ? props.updateJobs((prev) => ({
                ...prev,
                savedApplicants:
                  prev.savedApplicants > 0 ? prev.savedApplicants - 1 : 0,
                rejectedApplicants: prev.rejectedApplicants + 1,
              }))
            : statusType === "saveApplicant"
            ? props.updateJobs((prev) => ({
                ...prev,
                applicants: prev.applicants + 1,
                savedApplicants:
                  prev.savedApplicants > 0 ? prev.savedApplicants - 1 : 0,
              }))
            : "";
        } else if (props.applicantTabs === "rejected") {
          statusType === "saveApplicant"
            ? props.updateJobs((prev) => ({
                ...prev,
                savedApplicants: prev.savedApplicants + 1,
                rejectedApplicants:
                  prev.rejectedApplicants > 0 ? prev.rejectedApplicants - 1 : 0,
              }))
            : statusType === "rejectApplicant"
            ? props.updateJobs((prev) => ({
                ...prev,
                applicants: prev.applicants + 1,
                rejectedApplicants:
                  prev.rejectedApplicants > 0 ? prev.rejectedApplicants - 1 : 0,
              }))
            : "";
        }
        setApplicantOptions("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setApplicantOptions(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    document.getElementById("ref_1")?.scrollTo(0, 0);
  }, [props?.isLoading]);

  return (
    <div id="ref_1" className="candidatesListContainer mb-4">
      {props.isLoading
        ? Array.from(
            {
              length: 10,
            },
            (_, i) => (
              <div
                key={i}
                className="candidatesListCard cursor-pointer ps-2 d-flex align-items-start"
              >
                <LoadingShape
                  variant="image"
                  style={{ height: "60px", width: "60px" }}
                />
                <div className="inline-block col-md-7 ms-2">
                  <LoadingShape
                    className="my-1"
                    style={{ width: "50%", height: "14px" }}
                  />
                  <LoadingShape
                    className="my-1"
                    style={{ width: "80%", height: "14px" }}
                  />
                  <LoadingShape
                    className="my-1"
                    style={{ width: "30%", height: "14px" }}
                  />
                </div>
              </div>
            )
          )
        : userData?.length > 0 &&
          userData?.map((leaderboardUser, i) => {
            return (
              <div key={i}>
                <div
                  onClick={() => {
                    props.type === "jobApplicants"
                      ? props.selectApplicant(leaderboardUser)
                      : props?.onSelect(leaderboardUser?.User_Oid);
                  }}
                  className={`candidatesListCard cursor-pointer ps-2 d-flex position-relative ${
                    props.type === "jobApplicants" &&
                    applicantId.applicantId?._id ===
                      leaderboardUser?.applicantId?._id
                      ? "candidatesListCardActive"
                      : props.selectedUser &&
                        props.selectedUser[0]?.User_Oid ===
                          leaderboardUser?.User_Oid
                      ? "candidatesListCardActive"
                      : ""
                  } ${
                    props.type === "jobApplicants" &&
                    applicantId.applicantId?._id !==
                      leaderboardUser?.applicantId?._id &&
                    leaderboardUser?.viewed === false
                      ? "notVisitedcard"
                      : ""
                  }`}
                >
                  <div className="position-relative">
                    <img
                      className="candidatesProfilePic ms-1 me-3"
                      src={
                        props.type === "jobApplicants"
                          ? leaderboardUser?.applicantId?.Picture
                          : leaderboardUser?.Picture
                      }
                    />

                    {leaderboardUser?.rank && leaderboardUser?.rank < 4 ? (
                      <img
                        src={
                          leaderboardUser?.rank == 1
                            ? hackathoneRanking1
                            : leaderboardUser?.rank == 2
                            ? rankingLogo2
                            : rankingLogo3
                        }
                        className="candidatesRankingLogo"
                      />
                    ) : (
                      <div className="rankHld"></div>
                    )}
                  </div>
                  <div className="inline-block col-md-7">
                    <div className="candidatesProfileName">
                      {props.type === "jobApplicants"
                        ? leaderboardUser?.applicantId?.Name
                        : leaderboardUser?.Name}
                    </div>

                    <div className="sidebarInactiveText">
                      {props.type === "jobApplicants"
                        ? leaderboardUser?.applicantId?.LatestDesignation
                        : leaderboardUser?.LatestDesignation}
                    </div>
                    <div className="candidatesLoaction">
                      {" "}
                      {props.type === "jobApplicants"
                        ? leaderboardUser?.applicantId?.city
                        : leaderboardUser?.Location}
                    </div>
                  </div>
                  {props?.shortlistCandidate&&<div className="col-md-2 shortListedImgHld">
                    <img className="shortListedImg" src={leaderboardUser.shortlisted? ShortListActive:ShortListInactive} alt="shorlisted" onClick={()=>props.shortlistCandidate(leaderboardUser?.User_Oid,leaderboardUser.Name,leaderboardUser.Email)}/>
                    <div className="shortListedImgHov">
                     {leaderboardUser.shortlisted? "Remove shortlist" : "Shortlist candidate"}
                    </div>
                  </div>}
                  {props.type === "jobApplicants" && (
                    <div className="col-md-2 applicantOptions  position-relative ms-auto">
                      <img
                        src={threeDots}
                        className="applicantDots"
                        onClick={() =>
                          setApplicantOptions(leaderboardUser?.applicantId?._id)
                        }
                      />
                      {applicantOptions ===
                        leaderboardUser?.applicantId._id && (
                        <div
                          className="applicantOptionsDropdown"
                          ref={wrapperRef}
                        >
                          {props.applicantTabs === "all" && (
                            <span
                              className="p-2"
                              onClick={() => {
                                updateApplicantStatus(
                                  "shortListApplicant",
                                  "",
                                  leaderboardUser
                                );
                              }}
                            >
                              <img
                                className="me-2 ms-2 saveIcon applicantStatusImg"
                                src={saveIcon}
                                alt="edit"
                                width="20"
                                height="20"
                              />
                              <span>Shortlist</span>
                            </span>
                          )}
                          {props.applicantTabs !== "selected" && (
                            <span
                              className="p-2"
                              onClick={() => {
                                updateApplicantStatus(
                                  "saveApplicant",
                                  "",
                                  leaderboardUser
                                );
                              }}
                            >
                              <img
                                className="me-2 ms-2 applicantStatusImg"
                                src={selectIcon}
                                width="20"
                                height="20"
                              />
                              <span>Select</span>
                            </span>
                          )}
                          {props.applicantTabs !== "rejected" && (
                            <span
                              className="p-2"
                              onClick={() => {
                                updateApplicantStatus(
                                  "rejectApplicant",
                                  "",
                                  leaderboardUser
                                );
                              }}
                            >
                              <img
                                className="me-2 ms-2 applicantStatusImg"
                                src={RejectedIcon}
                                width="20"
                                height="20"
                              />
                              <span>Reject</span>
                            </span>
                          )}
                          {(props.applicantTabs === "rejected" ||
                            props.applicantTabs === "selected" ||
                            props.applicantTabs === "shortlisted") && (
                            <span
                              className="p-2"
                              onClick={() => {
                                updateApplicantStatus(
                                  `${
                                    props.applicantTabs === "rejected"
                                      ? "rejectApplicant"
                                      : props.applicantTabs === "shortlisted"
                                      ? "shortListApplicant"
                                      : "saveApplicant"
                                  }`,
                                  `${
                                    props.applicantTabs === "rejected"
                                      ? "revert_reject"
                                      : props.applicantTabs === "shortlisted"
                                      ? "revert_shortlist"
                                      : "unsave"
                                  }`,
                                  leaderboardUser
                                );
                              }}
                            >
                              <img
                                className="me-2 ms-2"
                                src={moveToAll}
                                width="20"
                                height="20"
                              />
                              <span>Move to All</span>
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      {!props.isLoading && props?.leaderboardLength > 20 && (
        <Pagination
          className="rainbow-m_auto candidatesListPagination"
          pages={
            props?.leaderboardLength > 0
              ? parseInt(props?.leaderboardLength / 20) + 1
              : 1
          }
          activePage={props?.activePage}
          onChange={props?.handleOnChangePagination}
          variant="shaded"
        />
      )}
    </div>
  );
}

export default CandidatesList;
